import "../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/modules/explorer/views/tree/node.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W227bMAx9z1cIAQa0DyqctOtFRYH9x1AMjETbXGTJkOS02dB/HyzbieU6WYfuKbF41eEhqW9b3OcOKvTsx+rn/s6V+Yb9XjCWfYk/jNkaJIW9YKvHBWNvC8Zuz8ju3suyQXZ/xu7hjN0qO2n4trga0l5FFWm1dYKRKdFReBwrrKOCIl9r2LcqmgzyXONr6ws0FYZTwMoLJtEEdO3xxjqFjjtQ1HjBbuqoHPA18GghmMY8PM5EZuyFVChFTL/9rMjwEqkog2DXWeeo8ei4R40yCGasweipcb51VVsa0qhBKTKFYNmQQm5N4J5+oWA7cBecQ57H+8Tz6jJaWU+BrBHMoYZAu+i+AleQ4cHWgsU0xhiJ0u7QRaQ2ILeFs41RkxBRhcf7XqbW3xUE4CDbUE/L4BpcPn/OlyIPG40q8TYApDCHRo/RT3zHKnmU1ihw+0MQNgDLcYcm+AH4meAOioJMkQQ/8vPqa2J0HcXzvse1gI23ugnxtCVPz/RYD77qiXHgTvc58GY1LdhNl9MOXa7ti2AlKYXmwNGjALWm2pOPzksKyH0NEtsEXxzUZ1qg7ZC+4U6iXDuqwO3TEn5NG+4vnea6G/b0nocrnQ1/L2PP5klGPxsfKN+3fZd3aXE0Ko1KhgKBToKu5oKC1knM2/EYSoAiac0IqKSF19O63kU3AwtuJyzovz8K7XBhaU1AE87Mt3U/3xyYAYn4P7euYtnV2p9iweRy45t0vSSt1lD7SScfnAvmbICAF/whU1jMupidBaN2vP4QKe4+NeLu/4nT74CPZPMB3HhB3AwjPQpLR2YbGT4K+3BEa7YsDMEjJ8Ntky69h8+jD+mV3wEKwhq957IkraYrtm+ikbaMKuMd1HOuX3G8G4ldNQaj7OQ6G3lW50t6FAw8HW2EvhLrrJ+3k7a4nXQn9qP+bD7YQR8ctpXxwTWy1X5aVrDFDq7lc6fTTqJu26CKM/tpmYP2uHwWkIeerAcSLZdJ82+0ldvTM/MXJ6MOE/yDjB8201wZuofDCDUJWl7ERxp/Z3CZbq/2JfS/IerktkYzhxoYqqBD5fjAXXlGJm/piax9BYJ7XLz9AQBQy98LCwAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapseContentPlaceholder = '_1jy7rhfa';
export var collapsedIcon = '_1jy7rhf9';
export var collapsedIconContainer = '_1jy7rhf7';
export var contentContainer = '_1jy7rhfc';
export var draggedOverEffect = '_1jy7rhfe';
export var draggingContainer = '_1jy7rhfd';
export var icon = '_1jy7rhf6';
export var iconsContainer = '_1jy7rhf8';
export var itemContent = '_1jy7rhf4';
export var itemRenameAnchor = '_1jy7rhf3';
export var itemRoot = '_1jy7rhf2';
export var levelIndent = 'var(--_1jy7rhf0)';
export var linkItemRoot = '_1jy7rhf1';
export var postfix = '_1jy7rhf5';